<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-img :src="require('@/assets/logo-laopt.jpg')" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-h6">
        Lao PT
      </v-list-item-title>
      <!-- <v-list-item-subtitle>
        PT
      </v-list-item-subtitle> -->
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "DefaultDrawerHeader"
};
</script>
<style lang=""></style>
